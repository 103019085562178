var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import gql from "graphql-tag";
import mixins from "vue-typed-mixins";
import moment from "moment";
import createCustomHealthCover from "../../../api/mutations/createCustomHealthCover";
import AddToCartButton from "../cart/AddToCartButton.vue";
import ProductListingLoading from "../general/ProductListingLoading.vue";
import { moneyFormat } from "../../../utils/filters";
import { allCountriesOptions, genderOptions, isCoverFor, } from "../../../data";
import NotificationsMixin from "../../ui/NotificationsMixin";
import AsyncStatus from "../../../utils/async";
import getAbsoluteUrl from "../../../router/getAbsoluteUrl";
import { toApiDate } from "../../../api/transform";
import addToOrCreateCart from "../../../api/addToOrCreateCart";
var urlDatePattern = "YYYY-MM-DD";
function getMomentFromParam($route, paramName) {
    var possible = moment($route.params[paramName], urlDatePattern);
    return possible.isValid() ? possible : undefined;
}
function getCoverFor(value) {
    if (value === undefined) {
        return undefined;
    }
    if (!isCoverFor(value)) {
        return undefined;
    }
    return value;
}
function getUrlParams(route) {
    return {
        courseStartDate: getMomentFromParam(route, "courseStart"),
        courseEndDate: getMomentFromParam(route, "courseEnd"),
        coverFor: getCoverFor(route.params.coverFor),
    };
}
var coverForOptions = [
    { value: "individual", label: "Individual" },
    { value: "couple", label: "Couple" },
    { value: "1-kid", label: "Family - 1 child" },
    { value: "2-kids", label: "Family - 2 children" },
    { value: "3-kids", label: "Family - 3 children" },
];
export default mixins(NotificationsMixin).extend({
    components: {
        ProductListingLoading: ProductListingLoading,
        AddToCartButton: AddToCartButton,
    },
    apollo: {
        healthCoverProviderFeatureSections: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query HealthCoverFeaturesQuery {\n        healthCoverProviderFeatureSections {\n          id\n          title\n          features {\n            id\n            title\n            hint\n          }\n        }\n      }\n    "], ["\n      query HealthCoverFeaturesQuery {\n        healthCoverProviderFeatureSections {\n          id\n          title\n          features {\n            id\n            title\n            hint\n          }\n        }\n      }\n    "]))),
        healthCoverQuotes: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query HealthCoverListingQuery(\n          $coverFor: String!\n          $courseStartDate: Date!\n          $courseEndDate: Date!\n        ) {\n          healthCoverQuotes(\n            coverFor: $coverFor\n            courseStartDate: $courseStartDate\n            courseEndDate: $courseEndDate\n          ) {\n            price {\n              amount\n              currency\n            }\n            provider {\n              id\n              name\n              quoteImageUrl\n              featureEntries {\n                feature {\n                  id\n                }\n                tick\n                text\n                fileUrl\n              }\n            }\n          }\n        }\n      "], ["\n        query HealthCoverListingQuery(\n          $coverFor: String!\n          $courseStartDate: Date!\n          $courseEndDate: Date!\n        ) {\n          healthCoverQuotes(\n            coverFor: $coverFor\n            courseStartDate: $courseStartDate\n            courseEndDate: $courseEndDate\n          ) {\n            price {\n              amount\n              currency\n            }\n            provider {\n              id\n              name\n              quoteImageUrl\n              featureEntries {\n                feature {\n                  id\n                }\n                tick\n                text\n                fileUrl\n              }\n            }\n          }\n        }\n      "]))),
            variables: function () {
                var params = this.quoteParams;
                if (!params) {
                    throw new Error("Shouldn't get here - should be skipped");
                }
                var coverFor = params.coverFor, courseStartDate = params.courseStartDate, courseEndDate = params.courseEndDate;
                return {
                    coverFor: coverFor,
                    courseStartDate: courseStartDate.format(urlDatePattern),
                    courseEndDate: courseEndDate.format(urlDatePattern),
                };
            },
            skip: function () {
                return !this.quoteParams;
            },
            result: function () {
                var _this = this;
                this.$nextTick(function () {
                    var _a;
                    (_a = _this.$refs.quoteResult) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" });
                });
            },
            error: function (e) {
                this.displayErrorMessage(e);
            },
        },
    },
    data: function () {
        return __assign(__assign({}, getUrlParams(this.$route)), { status: new AsyncStatus(), personalDetailsForms: [], genderOptions: genderOptions, countryOptions: allCountriesOptions, coverForOptions: coverForOptions, openQuotes: [], openHints: [] });
    },
    computed: {
        quoteParams: function () {
            var _a = getUrlParams(this.$route), courseStartDate = _a.courseStartDate, courseEndDate = _a.courseEndDate, coverFor = _a.coverFor;
            if (courseStartDate === undefined ||
                courseEndDate === undefined ||
                coverFor === undefined) {
                return undefined;
            }
            return {
                courseStartDate: courseStartDate,
                courseEndDate: courseEndDate,
                coverFor: coverFor,
            };
        },
        quoteCoverForLabel: function () {
            var _a;
            var params = this.quoteParams;
            if (!params) {
                return undefined;
            }
            return (_a = coverForOptions.find(function (o) { return o.value === params.coverFor; })) === null || _a === void 0 ? void 0 : _a.label;
        },
        quoteStartDate: function () {
            var params = this.quoteParams;
            if (!params) {
                return undefined;
            }
            return params.courseStartDate.format("DD/MM/YYYY");
        },
        quoteEndDate: function () {
            var params = this.quoteParams;
            if (!params) {
                return undefined;
            }
            return params.courseEndDate.format("DD/MM/YYYY");
        },
        // isValidHealthCoverQuote(): boolean {
        //   const params = getUrlParams(this.$route);
        //   if (Object.values(params).some((v) => v === undefined)) {
        //     return false;
        //   }
        //   if (!this.healthCoverQuotes || this.healthCoverQuotes.length === 0) {
        //     return false;
        //   }
        //   return true;
        // },
        // addToCartPrice(): undefined | string {
        //   if (
        //     !this.healthCoverQuote ||
        //     this.$apollo.queries.healthCoverQuotes.loading
        //   ) {
        //     return undefined;
        //   }
        //   return moneyFormat(this.healthCoverQuote.price);
        // },
        // healthCoverQuote(): HealthCoverQuote | undefined {
        //   if (!this.healthCoverQuotes || this.healthCoverQuotes.length === 0) {
        //     return undefined;
        //   }
        //   console.log("this.healthCoverQuotes", this.healthCoverQuotes);
        //   console.log("TODO: Scroll to results when new quotes come in")
        //   return this.healthCoverQuotes[0];
        // },
        futureDates: function () {
            return { to: moment() };
        },
        courseStartDisabledDates: function () {
            if (!this.courseEndDate) {
                return this.futureDates;
            }
            return { from: this.courseEndDate };
        },
        courseStartRuleDisabledDates: function () {
            return [undefined, this.futureDates.to.format("YYYY-MM-DD")];
        },
        courseEndDisabledDates: function () {
            if (!this.courseStartDate) {
                return this.futureDates;
            }
            return { to: moment(this.courseStartDate).add(1, "day") };
        },
        courseEndRuleDisabledDates: function () {
            return [undefined, this.courseEndDisabledDates.to.format("YYYY-MM-DD")];
        },
    },
    methods: {
        toggleQuoteOpen: function (quoteId) {
            if (this.openQuotes.includes(quoteId)) {
                this.openQuotes = this.openQuotes.filter(function (id) { return id !== quoteId; });
                this.openHints = [];
            }
            else {
                this.openQuotes.push(quoteId);
            }
        },
        toggleHintOpen: function (featureId) {
            if (this.openHints.includes(featureId)) {
                this.openHints = this.openHints.filter(function (id) { return id !== featureId; });
            }
            else {
                this.openHints.push(featureId);
            }
        },
        moneyFormat: moneyFormat,
        onCalculate: function () {
            if (!this.courseStartDate || !this.courseEndDate || !this.coverFor) {
                return;
            }
            var start = this.courseStartDate.format(urlDatePattern);
            var end = this.courseEndDate.format(urlDatePattern);
            var target = "/health-cover/details/".concat(this.coverFor, "/").concat(start, "/").concat(end);
            if (this.$route.path !== target) {
                this.$router.push(target);
            }
        },
        onSelectQuote: function (quote) {
            return __awaiter(this, void 0, void 0, function () {
                var valid;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.$validator.validate()];
                        case 1:
                            valid = _a.sent();
                            if (!valid) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.status.run((function () { return __awaiter(_this, void 0, void 0, function () {
                                    var data, productId;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.$apollo.mutate(createCustomHealthCover({
                                                    courseStartDate: toApiDate(this.courseStartDate),
                                                    courseEndDate: toApiDate(this.courseEndDate),
                                                    providerId: quote.provider.id,
                                                    coverFor: this.coverFor,
                                                }))];
                                            case 1:
                                                data = (_a.sent()).data;
                                                productId = data.createCustomHealthCover.productId;
                                                return [4 /*yield*/, addToOrCreateCart(this.$apollo, this.$store, this.$t.bind(this), productId)];
                                            case 2:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })())];
                        case 2:
                            _a.sent();
                            // TODO: Change back to router. This is a temp fix for cart number not increasing
                            // Note: probably to do with including __typename in all cart objects. I think when
                            // I set this up it was my first use of apollo and didn't understand the local cache.
                            document.location.href = getAbsoluteUrl(this.$store, this.$router, "/cart");
                            return [2 /*return*/];
                    }
                });
            });
        },
        onParamChange: function () {
            var target = "/health-cover/details";
            if (this.$route.path !== target) {
                this.$router.push(target);
            }
        },
        getFeatureEntry: function (feature, quote) {
            return quote.provider.featureEntries.find(function (e) { return e.feature.id === feature.id; });
        },
    },
});
var templateObject_1, templateObject_2;
